
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<'big' | 'medium'>,
      default: 'big',
    },
    type: {
      type: String as PropType<'button' | 'menu' | 'reset' | 'submit'>,
      default: 'button',
    },
    isDisabled: Boolean,
  },
  emits: ['click'],
})
