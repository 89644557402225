import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b976df4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-text block" }
const _hoisted_2 = ["type", "name", "autocomplete", "placeholder", "value", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "mt-2 text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("p", {
      class: _normalizeClass(["mb-1 ml-1", { 'opacity-50': _ctx.isDisabled, 'text-danger': _ctx.error }])
    }, _toDisplayString(_ctx.label || 'GIVE LABEL'), 3),
    _createElementVNode("input", _mergeProps({
      class: ["field-text__input text-base border rounded-md border-field bg-white py-2 px-4 w-full disabled:opacity-50 disabled:cursor-not-allowed", { 'placeholder-danger border-danger': _ctx.error }],
      type: _ctx.type,
      name: _ctx.name,
      autocomplete: _ctx.autocomplete,
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      disabled: _ctx.isDisabled
    }, _toHandlers(_ctx.updateListener)), null, 16, _hoisted_2),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}