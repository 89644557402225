import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button border px-4 rounded-md disabled:opacity-50 disabled:cursor-not-allowed text-white bg-primary", {
      'py-1 text-sm': _ctx.size === 'medium',
      'py-2 text-lg': _ctx.size === 'big',
    }]),
    type: _ctx.type,
    disabled: _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}