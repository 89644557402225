
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    label: String,
    modelValue: String,
    error: String,
    type: String as PropType<'text' | 'password' | 'email'>,
    name: String,
    autocomplete: String,
    placeholder: String,
    isDisabled: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, { attrs, emit }) {
    const isLazy = (attrs?.modelModifiers as { lazy?: boolean })?.lazy
    const updateListener = {
      [isLazy ? 'change' : 'input'](ev: Event) {
        emit('update:modelValue', (ev?.target as HTMLInputElement)?.value)
      },
    }
    return { updateListener }
  },
})
